import React, { useEffect } from 'react';
import { Group } from '../../shared/types';
import AnnouncementsUpsell from './AnnouncementsUpsell';
import { logGroupPageExposureEvent } from '../../shared/utils/logging';
import CloseButton from './CloseButton';

export type DismissibleAnnouncementUpsellBannerProps = {
  group: Group;
  eventContext: string;
  refetchCommunityInfo: () => Promise<void>;
  onDismiss: () => void;
};

const DismissibleAnnouncementUpsellBanner = ({
  group,
  eventContext,
  refetchCommunityInfo,
  onDismiss
}: DismissibleAnnouncementUpsellBannerProps): JSX.Element => {
  useEffect(() => {
    logGroupPageExposureEvent({
      groupId: group.id,
      exposureType: 'shoutUpsellBannerSeen',
      context: eventContext
    });
  }, [group.id, eventContext]);

  return (
    <div className='dismissable-announcement-upsell-banner'>
      <CloseButton
        className='dismissable-announcement-upsell-banner-icon'
        variant='white'
        onClick={onDismiss}
      />
      <AnnouncementsUpsell
        className='dismissable-announcement-upsell-banner-body'
        refetchCommunityInfo={refetchCommunityInfo}
        group={group}
        eventContext='groupHomepage'
      />
    </div>
  );
};

export default DismissibleAnnouncementUpsellBanner;
