import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { Button, Loading, Tooltip, createSystemFeedback } from 'react-style-guide';
import { CurrentUser, ExperimentationService } from 'Roblox';
import { createPortal, unmountComponentAtNode } from 'react-dom';
import {
  CommunityInfo,
  CommunityVisibility,
  ShoutInfoResponse,
  GroupDetailsPolicies
} from '../types';
import { Group } from '../../shared/types';
import communityLinksService from '../services/communityLinksService';
import GuildedAnnouncementUpsell from '../components/GuildedAnnouncementUpsell';
import DismissibleAnnouncementUpsellBanner from '../components/DismissibleAnnouncementUpsellBanner';
import {
  getHasOwnerDismissedUpsell,
  getHasUserDismissedNotificationsUpsell,
  setOwnerHasDismissedUpsell,
  setUserHasDismissedNotificationsUpsell
} from '../utils/localStorage';
import { groupAnnouncementsConfig } from '../translation.config';
import AnnouncementDisplay from '../components/AnnouncementDisplay';
import ViewAllGuildedAnnouncementsButton from '../components/ViewAllGuildedAnnouncementsButton';
import CommunityLinkDisplay from '../components/CommunityLinkDisplay';
import { logGroupPageClickEvent } from '../../shared/utils/logging';
import { EventContext } from '../constants/eventConstants';
import { EventContext as SharedEventContext } from '../../shared/constants/eventConstants';
import NotificationsUpsellBanner from '../components/NotificationsUpsellBanner';
import groupsService from '../services/groupsService';
import { experimentLayer } from '../constants/experimentConstants';

const [SystemFeedback, systemFeedbackService] = createSystemFeedback();

export type GroupAnnouncementsSectionProps = {
  group: Group;
  propsCommunityInfo: CommunityInfo | null;
  isOwner: boolean;
  announcement: ShoutInfoResponse | null;
  joinGroup: () => Promise<boolean>;
  allowedToJoinGroup: boolean;
  policies: GroupDetailsPolicies;
} & WithTranslationsProps;

type NotificationsUpsellConfig = {
  inTreatment: boolean;
};

type ExperimentLayerValues = {
  notificationsUpsellConfig?: NotificationsUpsellConfig;
};

const GroupAnnouncementsSection = ({
  group,
  propsCommunityInfo,
  isOwner,
  announcement,
  joinGroup,
  allowedToJoinGroup,
  policies,
  translate
}: GroupAnnouncementsSectionProps): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(false);
  const [communityInfo, setCommunityInfo] = useState<CommunityInfo | null>(propsCommunityInfo);

  const [showOwnerUpsell, setShowOwnerUpsell] = useState(!getHasOwnerDismissedUpsell());

  const ownerDismissedUpsell = useCallback(() => {
    setOwnerHasDismissedUpsell();
    setShowOwnerUpsell(false);
    logGroupPageClickEvent({
      groupId: group.id,
      clickTargetType: 'dismissShoutUpsellBanner',
      context: SharedEventContext.GroupHomepage
    });
  }, [group.id]);

  const [experimentExposureLogged, setExperimentExposureLogged] = useState(false);
  const [isInExperimentTreatment, setIsInExperimentTreatment] = useState(false);

  const [isNotificationsUpsellDismissed, setIsNotificationsUpsellDismissed] = useState(
    getHasUserDismissedNotificationsUpsell(group.id)
  );

  const userDismissedNotificationsUpsell = useCallback(() => {
    setIsNotificationsUpsellDismissed(true);
    setUserHasDismissedNotificationsUpsell(group.id);
    logGroupPageClickEvent({
      groupId: group.id,
      clickTargetType: 'dismissNotificationUpsellBanner',
      context: SharedEventContext.GroupHomepage
    });
  }, [group.id]);

  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(false);
  const [isMemberOfGroup, setIsMemberOfGroup] = useState(false);

  const fetchExperimentConfig = useCallback(async () => {
    const experimentConfig: ExperimentLayerValues = await ExperimentationService.getAllValuesForLayer(
      experimentLayer
    );
    setIsInExperimentTreatment(experimentConfig?.notificationsUpsellConfig?.inTreatment ?? false);
  }, []);

  const fetchCommunityInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const communityInfoResponse: CommunityInfo = await communityLinksService.getLinkedCommunity(
        group.id
      );

      setCommunityInfo(communityInfoResponse);
    } catch {
      setCommunityInfo(null);
    } finally {
      setIsLoading(false);
    }
  }, [group]);

  const fetchGroupMembershipDetails = useCallback(async () => {
    try {
      const membership = await groupsService.getGroupMembership(group.id);
      setIsNotificationsEnabled(membership?.isNotificationsEnabled ?? false);
      setIsMemberOfGroup(membership?.userRole?.role.rank !== 0 ?? false);
    } catch (error) {
      // fall back to default values
      setIsNotificationsEnabled(false);
      setIsMemberOfGroup(false);
    }
  }, [group]);

  const toggleNotifications = useCallback(
    async (eventContext: string) => {
      const newNotificationsValue = !isNotificationsEnabled;
      try {
        logGroupPageClickEvent({
          groupId: group.id,
          clickTargetType: `toggleNotifications${newNotificationsValue ? 'On' : 'Off'}`,
          context: eventContext
        });

        setUserHasDismissedNotificationsUpsell(group.id);
        setIsNotificationsUpsellDismissed(true);
        setIsNotificationsEnabled(newNotificationsValue);

        // When a non-member toggles on notifications for a group with public entry allowed have them auto-join the group.
        if (
          newNotificationsValue &&
          !isMemberOfGroup &&
          allowedToJoinGroup &&
          group.publicEntryAllowed
        ) {
          try {
            await joinGroup();
            setIsMemberOfGroup(true);
          } catch (error) {
            // If group join failed then reset the UI state. joinGroup will show handle showing a toast to the user with the error.
            setIsNotificationsEnabled(!newNotificationsValue);
            return;
          }
        }
        await groupsService.updateGroupNotificationPreference(group.id, newNotificationsValue);
      } catch (error) {
        // On error reset UI state and show a toast that subscribing to notifications failed
        setIsNotificationsEnabled(!newNotificationsValue);
        systemFeedbackService.warning(translate('Message.SubscribeToNotificationsError'));
      }
    },
    [
      group.id,
      isNotificationsEnabled,
      isMemberOfGroup,
      group.publicEntryAllowed,
      joinGroup,
      allowedToJoinGroup,
      translate
    ]
  );

  const handleNotificationBellClicked = useCallback(async () => {
    await toggleNotifications(EventContext.NotificationBell);
  }, [toggleNotifications]);

  const handleNotificationUpsellClicked = useCallback(async () => {
    await toggleNotifications(EventContext.NotificationUpsell);
  }, [toggleNotifications]);

  useEffect(() => {
    if (communityInfo) {
      // eslint-disable-next-line no-void
      void Promise.all([fetchGroupMembershipDetails(), fetchExperimentConfig()]);
    }
  }, [fetchGroupMembershipDetails, fetchExperimentConfig, communityInfo]);

  let body = null;

  if (communityInfo && announcement) {
    const showNotificationsUpsell =
      isInExperimentTreatment &&
      // Allow non-members of public entry groups to opt-in to notifications and auto-join the group
      (isMemberOfGroup || (group.publicEntryAllowed && allowedToJoinGroup)) &&
      !isNotificationsUpsellDismissed &&
      !isNotificationsEnabled;

    if (showNotificationsUpsell && !experimentExposureLogged) {
      // only log experiment exposure once, and only if we show the notifications upsell
      setExperimentExposureLogged(true);
      ExperimentationService.logLayerExposure(experimentLayer);
    }

    body = (
      <Fragment>
        {showNotificationsUpsell && (
          <NotificationsUpsellBanner
            group={group}
            onNotifyClicked={handleNotificationUpsellClicked}
            onDismiss={userDismissedNotificationsUpsell}
            eventContext={SharedEventContext.GroupHomepage}
          />
        )}
        <AnnouncementDisplay groupId={group.id} announcement={announcement} policies={policies} />
      </Fragment>
    );
  }

  if (!communityInfo && isOwner && showOwnerUpsell && CurrentUser.is13orOver) {
    const upsellContainer = document.getElementById('group-announcements-upsell-container');
    if (!upsellContainer) {
      return null;
    }

    // prevent duplication of upsell
    unmountComponentAtNode(upsellContainer);

    // use a portal here so we can maintain consistent React state and not have
    // bi-directional communication with angular components
    return createPortal(
      <DismissibleAnnouncementUpsellBanner
        onDismiss={ownerDismissedUpsell}
        refetchCommunityInfo={fetchCommunityInfo}
        group={group}
        eventContext={SharedEventContext.GroupHomepage}
      />,
      upsellContainer
    );
  }

  if (communityInfo && !announcement && isOwner && CurrentUser.is13orOver) {
    body = <GuildedAnnouncementUpsell communityInfo={communityInfo} groupId={group.id} />;
  }

  if (!body) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className='section group-announcements'>
      <div className='container-header group-announcements-header'>
        <h2>{translate('Heading.Announcements')}</h2>
        {!!announcement && isInExperimentTreatment && (
          <Tooltip
            id='group-notifications-tooltip'
            placement='left'
            content={translate(
              isNotificationsEnabled ? 'Action.TurnNotificationsOff' : 'Action.TurnNotificationsOn'
            )}>
            <Button
              variant={Button.variants.secondary}
              size={Button.sizes.extraSmall}
              width={Button.widths.default}
              className='group-announcements-notifications-icon'
              onClick={handleNotificationBellClicked}>
              <span
                className={`icon-notifications-bell${isNotificationsEnabled ? ' followed' : ''}`}
                aria-label='notify'
              />
            </Button>
          </Tooltip>
        )}
      </div>
      <div className='section-content'>{body}</div>
      {communityInfo && announcement && CurrentUser.is13orOver && (
        <div className='group-announcements-view-all-button'>
          <ViewAllGuildedAnnouncementsButton communityInfo={communityInfo} />
        </div>
      )}
      {communityInfo &&
        communityInfo.visibility === CommunityVisibility.Public &&
        CurrentUser.is13orOver && (
          <CommunityLinkDisplay groupName={group.name} communityInfo={communityInfo} />
        )}
      <SystemFeedback />
    </div>
  );
};

export default withTranslations(GroupAnnouncementsSection, groupAnnouncementsConfig);
