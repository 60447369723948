import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupAnnouncementsConfig } from '../translation.config';
import CreateCommunityDialog from './dialogs/CreateCommunityDialog';
import LinkCommunityDialog from './dialogs/LinkCommunityDialog';
import { logCommunityLinkFlowStarted, logCommunityCreateFlowStarted } from '../utils/logging';
import { Group } from '../../shared/types';

type AnnouncementsUpsellProps = {
  group: Group;
  // eslint-disable-next-line react/require-default-props
  className?: string;
  refetchCommunityInfo: () => Promise<void>;
  eventContext: string;
} & WithTranslationsProps;

const AnnouncementsUpsell = ({
  group,
  className = '',
  refetchCommunityInfo,
  eventContext,
  translate
}: AnnouncementsUpsellProps): JSX.Element => {
  const [isLinkDialogVisible, setIsLinkDialogVisible] = useState(false);
  const hideLinkDialog = useCallback(() => setIsLinkDialogVisible(false), []);
  const showLinkDialog = useCallback(() => {
    logCommunityLinkFlowStarted({ groupId: group.id, context: eventContext });
    setIsLinkDialogVisible(true);
  }, [group.id, eventContext]);

  const [isCreateDialogVisible, setIsCreateDialogVisible] = useState(false);
  const hideCreateDialog = useCallback(() => setIsCreateDialogVisible(false), []);
  const showCreateDialog = useCallback(() => {
    logCommunityCreateFlowStarted({ groupId: group.id, context: eventContext });
    setIsCreateDialogVisible(true);
  }, [group.id, eventContext]);

  return (
    <div className={classNames('announcements-upsell', className)}>
      <h2>{translate('Heading.AnnouncementsUpsell')}</h2>
      <div>{translate('Description.AnnouncementsUpsell')}</div>
      <div className='announcements-upsell-buttons'>
        <Button onClick={showCreateDialog} variant='growth'>
          {translate('Action.CreateCommunity')}
        </Button>
        <Button onClick={showLinkDialog} variant='secondary'>
          {translate('Action.LinkCommunity')}
        </Button>
      </div>
      {isCreateDialogVisible && (
        <CreateCommunityDialog
          groupId={group.id}
          defaultName={group.name}
          eventContext={eventContext}
          onClose={hideCreateDialog}
          onCreateSuccess={refetchCommunityInfo}
          openLinkCommunityDialog={showLinkDialog}
        />
      )}
      {isLinkDialogVisible && (
        <LinkCommunityDialog
          groupId={group.id}
          eventContext={eventContext}
          onClose={hideLinkDialog}
          onLinkSuccess={refetchCommunityInfo}
          openCreateCommunityDialog={showCreateDialog}
        />
      )}
    </div>
  );
};

export default withTranslations(AnnouncementsUpsell, groupAnnouncementsConfig);
