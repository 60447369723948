import React, { useCallback, useState } from 'react';
import { CurrentUser } from 'Roblox';
import { abbreviateNumber } from 'core-utilities';
import classNames from 'classnames';
import communityLinksService from '../services/communityLinksService';
import { logGroupPageClickEvent } from '../../shared/utils/logging';
import { EventContext as SharedEventContext } from '../../shared/constants/eventConstants';

const REACTION_UPDATE_DEBOUNCE_MS = 1000;

export type ReactionButtonProps = {
  initialCount: number;
  userHasInteracted: boolean;
  announcementId: string;
  groupId: number;
  showReactionCount: boolean;
};

const ReactionButton = ({
  initialCount,
  userHasInteracted,
  announcementId,
  groupId,
  showReactionCount
}: ReactionButtonProps): JSX.Element => {
  const [count, setCount] = useState(initialCount);
  const [isInteracted, setIsInteracted] = useState(userHasInteracted);
  const [isReactionCountVisible] = useState(showReactionCount);
  const [localIsInteracted, setLocalIsInteracted] = useState(isInteracted);

  const [reactionTimeoutId, setReactionTimeoutId] = useState<number | null>(null);

  const toggleInteraction = useCallback(() => {
    // Optimistically update the UI
    const optimisticIsInteracted = !localIsInteracted;
    setLocalIsInteracted(optimisticIsInteracted);
    setCount(currentCount => currentCount + (optimisticIsInteracted ? 1 : -1));

    if (reactionTimeoutId) {
      clearTimeout(reactionTimeoutId);
      setReactionTimeoutId(null);
    }

    if (optimisticIsInteracted !== isInteracted) {
      const timeoutId = setTimeout(async () => {
        try {
          await (optimisticIsInteracted
            ? communityLinksService.incrementReactionCount({ announcementId, groupId })
            : communityLinksService.decrementReactionCount({ announcementId, groupId }));

          logGroupPageClickEvent({
            groupId,
            clickTargetType: optimisticIsInteracted ? 'shoutReactionAdded' : 'shoutReactionRemoved',
            clickTargetId: announcementId,
            context: SharedEventContext.GroupHomepage
          });

          setIsInteracted(optimisticIsInteracted);
        } catch (error) {
          setCount(currentCount => currentCount + (optimisticIsInteracted ? -1 : 1));
          setLocalIsInteracted(isInteracted);
        } finally {
          setReactionTimeoutId(null);
        }
      }, REACTION_UPDATE_DEBOUNCE_MS);

      setReactionTimeoutId(timeoutId);
    }
  }, [announcementId, groupId, isInteracted, localIsInteracted, reactionTimeoutId]);

  // Handle keyboard interactions
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      // eslint-disable-next-line no-void
      void toggleInteraction();
    }
  };

  const isInteractable = CurrentUser.isAuthenticated;

  return (
    <div
      className={classNames(
        'reaction-button',
        isInteractable && 'interactable',
        localIsInteracted && 'interacted'
      )}
      onClick={isInteractable ? toggleInteraction : undefined}
      onKeyDown={isInteractable ? handleKeyDown : undefined}
      role='button'
      tabIndex={0}
      aria-pressed={localIsInteracted}>
      <span className='reaction-button-icon icon-votes-gray' />
      {isReactionCountVisible && (
        <span className='reaction-button-count'>
          {abbreviateNumber.getAbbreviatedValue(count, undefined, 1000)}
        </span>
      )}
    </div>
  );
};
export default ReactionButton;
