import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Linkify } from 'Roblox';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { ShoutInfoResponse, GroupDetailsPolicies } from '../types';
import { groupAnnouncementsConfig } from '../translation.config';
import UserCard from './UserCard';
import ReactionButton from './ReactionButton';
import AnnouncementEmbeds from './AnnouncementEmbeds';

const CONTENT_HEIGHT_LIMIT_PX = 400;

export type AnnouncementDisplayProps = {
  announcement: ShoutInfoResponse;
  groupId: number;
  policies: GroupDetailsPolicies;
} & WithTranslationsProps;

type StringWithEscapeHTML = string & { escapeHTML: () => string };

const AnnouncementDisplay = ({
  announcement,
  groupId,
  policies,
  translate
}: AnnouncementDisplayProps): JSX.Element => {
  const {
    title,
    content,
    imageURL,
    likeCount,
    userHasReactedToShout,
    areReactionCountsVisible,
    announcementId
  } = announcement;

  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current && contentRef.current.offsetHeight > CONTENT_HEIGHT_LIMIT_PX) {
      setIsTruncated(true);
    }
  }, []);

  const toggleTruncation = useCallback(() => {
    setIsTruncated(!isTruncated);
  }, [isTruncated]);

  // be cautious editing below code. this can easily create a XSS vulnerability if not handled properly.
  const linkifiedContent = useMemo(() => {
    if (Linkify !== undefined) {
      // we need to cast content to new type because typescript is unaware of the escapeHTML added to the string prototype
      const escapedContent = (content as StringWithEscapeHTML).escapeHTML();
      return Linkify.String(escapedContent);
    }
    // unsafe to return any content if Linkify is not defined because we need to use escapeHTML method
    return '';
  }, [content]);

  return (
    <div className='announcement-display'>
      <UserCard announcement={announcement} />
      <div className='announcement-display-image-aspect-ratio-wrapper'>
        <img
          className='announcement-display-image-aspect-ratio-wrapper-content'
          src={imageURL}
          alt=''
        />
      </div>
      <h2>{title}</h2>
      <div className='announcement-display-body'>
        <p
          className={classNames('announcement-display-body-content', isTruncated && 'truncated')}
          ref={contentRef}
          dangerouslySetInnerHTML={{ __html: linkifiedContent }}
        />
        {isTruncated && (
          <button
            className='announcement-display-show-more'
            type='button'
            onClick={toggleTruncation}>
            {translate('Action.ShowMore')}
          </button>
        )}
      </div>
      {policies.displayMarketplaceEmbed && (
        <AnnouncementEmbeds content={content} groupId={groupId} />
      )}
      <div className='announcement-display-reaction-row'>
        <ReactionButton
          initialCount={likeCount}
          userHasInteracted={userHasReactedToShout}
          groupId={groupId}
          announcementId={announcementId}
          showReactionCount={areReactionCountsVisible}
        />
      </div>
    </div>
  );
};
export default withTranslations(AnnouncementDisplay, groupAnnouncementsConfig);
