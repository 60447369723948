import { eventStreamService } from 'core-roblox-utilities';
import {
  EventType,
  EventDialogType,
  EventDialogState,
  EventEntityType
} from '../constants/eventConstants';
import { EventContext as SharedEventContext } from '../../shared/constants/eventConstants';

export const logCommunityCreateFlowStarted = ({
  groupId,
  context
}: {
  groupId: number;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.CommunityDialogStateChanged, context, {
    dialogType: EventDialogType.Create,
    dialogState: EventDialogState.Started,
    entityType: EventEntityType.Group,
    entityId: groupId
  });
};

export const logCommunityCreateFlowCompleted = ({
  groupId,
  communityId,
  announcementChannelId,
  context
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.CommunityDialogStateChanged, context, {
    dialogType: EventDialogType.Create,
    dialogState: EventDialogState.Completed,
    entityType: EventEntityType.Group,
    entityId: groupId,
    guildedServerId: communityId,
    announcementChannelId
  });
};

export const logCommunityLinkFlowStarted = ({
  groupId,
  context
}: {
  groupId: number;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.CommunityDialogStateChanged, context, {
    dialogType: EventDialogType.Link,
    dialogState: EventDialogState.Started,
    entityType: EventEntityType.Group,
    entityId: groupId
  });
};

export const logCommunityLinkFlowCompleted = ({
  groupId,
  communityId,
  announcementChannelId,
  context
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.CommunityDialogStateChanged, context, {
    dialogType: EventDialogType.Link,
    dialogState: EventDialogState.Completed,
    entityType: EventEntityType.Group,
    entityId: groupId,
    guildedServerId: communityId,
    announcementChannelId
  });
};

export const logCommunityChangeChannelFlowStarted = ({
  groupId,
  communityId,
  announcementChannelId
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context?: string;
}): void => {
  eventStreamService.sendEventWithTarget(
    EventType.CommunityDialogStateChanged,
    SharedEventContext.ConfigureGroup,
    {
      dialogType: EventDialogType.ChangeChannel,
      dialogState: EventDialogState.Started,
      entityType: EventEntityType.Group,
      entityId: groupId,
      guildedServerId: communityId,
      announcementChannelId
    }
  );
};

export const logCommunityChangeChannelFlowCompleted = ({
  groupId,
  communityId,
  announcementChannelId,
  prevAnnouncementChannelId
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  prevAnnouncementChannelId: string;
  context?: string;
}): void => {
  eventStreamService.sendEventWithTarget(
    EventType.CommunityDialogStateChanged,
    SharedEventContext.ConfigureGroup,
    {
      dialogType: EventDialogType.ChangeChannel,
      dialogState: EventDialogState.Completed,
      entityType: EventEntityType.Group,
      entityId: groupId,
      guildedServerId: communityId,
      announcementChannelId,
      prevAnnouncementChannelId
    }
  );
};

export const logCommunityUnlinkFlowStarted = ({
  groupId,
  communityId,
  announcementChannelId
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context?: string;
}): void => {
  eventStreamService.sendEventWithTarget(
    EventType.CommunityDialogStateChanged,
    SharedEventContext.ConfigureGroup,
    {
      dialogType: EventDialogType.Unlink,
      dialogState: EventDialogState.Started,
      entityType: EventEntityType.Group,
      entityId: groupId,
      guildedServerId: communityId,
      announcementChannelId
    }
  );
};

export const logCommunityUnlinkFlowCompleted = ({
  groupId,
  communityId,
  announcementChannelId
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context?: string;
}): void => {
  eventStreamService.sendEventWithTarget(
    EventType.CommunityDialogStateChanged,
    SharedEventContext.ConfigureGroup,
    {
      dialogType: EventDialogType.Unlink,
      dialogState: EventDialogState.Completed,
      entityType: EventEntityType.Group,
      entityId: groupId,
      guildedServerId: communityId,
      announcementChannelId
    }
  );
};
